export const OurGovernanceData = [

    {
        src: "https://ik.imagekit.io/mhcockpit1/Our%20Governance/CEO.jpg",
        name: "Ahamed Subair",
        role: "Founder Chairman",
        linkedin: "https://www.linkedin.com/in/ahamed-subair-142255aa/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
    },
    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/yasir%20sir.jpg',
        alt: 'Our Governance',
        name: 'Ahamed Yasir',
        role: 'CEO',
    },
    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/Haja.jpg',
        alt: 'Our Governance',
        name: 'Haja',
        role: 'Director Advisory Board',
    },
    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/Alhaf%20Malik%20New.webp',
        alt: 'Our Governance',
        name: 'Alhaf Malik',
        role: 'CTO',
    },
    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/Yacqub%20Lye.webp',
        alt: 'Our Governance',
        name: 'Yacqub Lyer',
        role: 'Director Sales And Marketing - UAE',
    },

]

export const MHCockpitBoardMembers = [

    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/Burhan%20Mohamed.jpg',
        alt: 'Our Governance',
        name: 'Burhan Mohamed',
        role: 'COO - GCC Operations',
    },
    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/Jehath.jpg',
        alt: 'Our Governance',
        name: 'Jehad Al Shehri',
        role: 'Director - GCC Operations',
    },
    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/KIRIAKOS%20KIRANOS.jpg',
        alt: 'Our Governance',
        name: 'Capt. Kiriakos Kiranos',
        role: 'Director European Operations',
    },

]

export const MHIntellectTeam = [

    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/Sonam%20Mishra.jpg',
        alt: 'Our Governance',
        name: 'Sonam Mishra',
        role: 'Head of operations',
    },
    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/VINOTH.jpg',
        alt: 'Our Governance',
        name: 'Vinoth',
        role: 'Head Program Delivery',
    },
    // {
    //     src: '/Our Governance/Arun.jpg',
    //     alt: 'Our Governance',
    //     name: 'Arunachala Raam',
    //     role: 'Project Head',
    // },
    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/Santosh.jpg',
        alt: 'Our Governance',
        name: 'Santhosh',
        role: 'Project Head',
    },
    // {
    //     src: '/Our Governance/Santosh.jpg',
    //     alt: 'Our Governance',
    //     name: 'Santhosh',
    //     role: 'Instructor',
    // },
    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/Selva.jpg',
        alt: 'Our Governance',
        name: 'Selva',
        role: '',
    },
    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/Gowtham.jpg',
        alt: 'Our Governance',
        name: 'Gowtham',
        role: '',
    },
    {
        src: 'https://ik.imagekit.io/mhcockpit1/MHB/Our%20Governance/Manikam.jpg',
        alt: 'Our Governance',
        name: 'Manickam',
        role: '',
    },

]