import React from 'react'
// import ApplyNowModal from '../../Components/Apply Now/ApplyNowModal';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
import { GoDotFill } from "react-icons/go";
import { useNavigate } from 'react-router-dom';

export default function WinterWorkshop() {

  // const [isModalOpen, setIsModalOpen] = useState(false);

  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);

  const navigate = useNavigate();

  const creatingFuture = [

    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder/robot.png?updatedAt=1731128428276",
      titleLine1: "Learn to Build",
      titleLine2: "your own Robot",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder/AI.png?updatedAt=1731128429473",
      titleLine1: "Discover the",
      titleLine2: "magic of AI",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder/ML.png?updatedAt=1731128432241",
      titleLine1: "Decode with",
      titleLine2: "Machine Learning",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder/explore%20world.png?updatedAt=1731128429512",
      titleLine1: "Explore the wonders",
      titleLine2: "of flight with us",
    },

  ]

  const winterWorkshop = [

    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(2)/ai%20and%20robo.png?updatedAt=1731128428338",
      titleLine1: "Robotics",
      titleLine2: "with AI",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(2)/stem.png?updatedAt=1731066178364",
      titleLine1: "STEM",
      titleLine2: "& Tinkering",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(2)/drone.png?updatedAt=1731066178330",
      titleLine1: "Drones & Flight",
      titleLine2: "Simulations",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(2)/3d.png?updatedAt=1731066178132",
      titleLine1: "3D Designing",
      titleLine2: "& Printing",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(2)/futurefarming.png?updatedAt=1731066178333",
      titleLine1: "Future",
      titleLine2: "Farming",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(2)/MLARVR%20coding%20.png?updatedAt=1731070362917",
      titleLine1: "Coding, ML,",
      titleLine2: "AR & VR",
    },

  ]

  const pathway = [

    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(3)/CT%20and%20PS.png?updatedAt=1731128428363",
      titleLine1: "Critical Thinking &",
      titleLine2: "Problem-Solving",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(3)/Technical%20Proficiency%20in%20STEM.png?updatedAt=1731128428351",
      titleLine1: "Technical Proficiency",
      titleLine2: "in STEM",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(3)/Coding%20Skills%20Development.png?updatedAt=1731128428272",
      titleLine1: "Coding Skills",
      titleLine2: "Development",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(3)/collboration%20team%20work.png?updatedAt=1731128429414",
      titleLine1: "Colloboration &",
      titleLine2: "Teamwork",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(3)/Project-Based%20.png?updatedAt=1731128429407",
      titleLine1: "Project-Based",
      titleLine2: "Learning",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(3)/Exposure%20to%20Emerging%20Technologies.png?updatedAt=1731128428344",
      titleLine1: "Exposure to Emerging",
      titleLine2: "Technologies",
    },

  ]

  const whychoose1 = [

    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(4)/hands%20on%20learning.png?updatedAt=1731128428279",
      title: "Hands-On Learning",
      titleLine1: "Interactive sessions",
      titleLine2: "with the latest tools.",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(4)/Expert%20Guidance.png?updatedAt=1731128429438",
      title: "Expert Guidance",
      titleLine1: "Learn from industry",
      titleLine2: "professionals.",
    },
    {
      src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(4)/Future-Ready%20Skills.png?updatedAt=1731128428424",
      title: "Future-Ready Skills",
      titleLine1: "Prepares students for",
      titleLine2: "tech-driven careers.",
    },

  ]

  // const whychoose2 = [

  //   {
  //     src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(4)/3d.png?updatedAt=1731128429460",
  //     title: "Hands-On Learning",
  //     titleLine1: "Drive into 3D printing and",
  //     titleLine2: "see your imagination take shape!",
  //   },
  //   {
  //     src: "https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Icons/New%20folder%20(4)/drone.png?updatedAt=1731128428340",
  //     title: "Hands-On Learning",
  //     titleLine1: "Interactive sessions",
  //     titleLine2: "with the latest tools.",
  //   },

  // ]

  return (
    <div>
      <div>
        <img src='https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/cover%20page.jpg?updatedAt=1731128428553' alt='Winter Workshop' />
      </div>
      <div className='bg-[#0a195c] py-20'>
        <FadeInSectionDownToUp>
          <p className='text-center text-6xl max-sm:text-2xl text-white font-extralight'>Creating</p>
        </FadeInSectionDownToUp>
        <FadeInSectionDownToUp>
          <p className='text-center text-6xl max-sm:text-2xl font-bold text-white'>
            Architects of the Future <span className='font-extralight'>in</span> <span className='text-8xl max-sm:text-4xl'>14</span> <span className='font-normal'>Days.</span>
          </p>
        </FadeInSectionDownToUp>
        <div className='flex justify-center items-center pt-10'>
          <div className='grid grid-cols-4 max-sm:grid-cols-2 gap-20 max-sm:gap-10'>
            {creatingFuture.map((icon, index) => (
              <div key={index}>
                <FadeInSectionDownToUp>
                  <div className='flex justify-center items-center'>
                    <img className='w-36 max-sm:w-24' src={icon.src} alt='Winter Workshop' />
                  </div>
                  <div className='flex justify-center items-center pt-5'>
                    <div>
                      <p className='text-white text-start text-2xl max-sm:text-lg font-bold'>{icon.titleLine1}</p>
                      <p className='text-white text-start text-2xl max-sm:text-lg font-bold'>{icon.titleLine2}</p>
                    </div>
                  </div>
                </FadeInSectionDownToUp>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='py-10'>
        <FadeInSectionDownToUp>
          <p className='text-center text-3xl max-sm:text-2xl font-semibold'>
            What is the winter <span className='text-[#0a195c]'>workshop?</span>
          </p>
        </FadeInSectionDownToUp>
        <FadeInSectionDownToUp>
          <p className='px-48 max-sm:px-10 py-10 text-xl text-[#969696]'>
            Join us at ASPAM IIS for an immersive 14-day winter workshop where young minds explore and create using the latest in STEM, robotics, AI, drones, and more! This workshop is designed to empower students with practical skills and knowledge in cutting-edge fields.
          </p>
        </FadeInSectionDownToUp>
        <div className='flex justify-center items-center py-10'>
          <div className='grid grid-cols-3 max-sm:grid-cols-2 gap-x-40 max-sm:gap-x-5 gap-y-10'>
            {winterWorkshop.map((icon, index) => (
              <div key={index}>
                <FadeInSectionDownToUp>
                  <div className='flex justify-center items-center'>
                    <img className='w-40' src={icon.src} alt='Winter Workshop' />
                  </div>
                  <div className='flex justify-center items-center'>
                    <div>
                      <p className='text-start text-2xl max-sm:text-lg text-[#102e83]'>{icon.titleLine1}</p>
                      <p className='text-start text-2xl max-sm:text-lg text-[#102e83]'>{icon.titleLine2}</p>
                    </div>
                  </div>
                </FadeInSectionDownToUp>
              </div>
            ))}
          </div>
        </div>
        <div className='py-20'>
          <div className='flex max-sm:block justify-center items-center px-36 xl:px-36 sm:px-10 max-sm:px-10 py-10'>
            <div className='w-1/2 xl:w-1/2 sm:w-full max-sm:w-full'>
              <img className='rounded-3xl' src='https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Robotics%20with%20AI.jpg?updatedAt=1731128428443' alt='Winter Workshop' />
            </div>
            <div className='px-20 xl:px-20 sm:px-10 max-sm:px-0 max-sm:pt-5 w-full text-[#102e83]'>
              <FadeInSectionDownToUp>
                <p className='text-4xl'>Robotics & AI</p>
                <p className='text-2xl font-semibold'>Shaping the Future!</p>
                <p>
                  Imagine a world where robots think and act like humans! Join our Robotics & AI workshop to build and program your own intelligent robots. Discover how AI-powered robots will revolutionize industries, transform daily life, and change the world. Imagine robots assisting in hospitals, helping people with disabilities, and exploring space.
                </p>
              </FadeInSectionDownToUp>
            </div>
          </div>
          <div className='flex max-sm:flex-col-reverse justify-center items-center px-36 xl:px-36 sm:px-10 max-sm:px-10 py-10'>
            <div className='px-20 xl:px-20 sm:px-10 max-sm:px-0 max-sm:pt-5 w-full text-end text-[#102e83]'>
              <FadeInSectionDownToUp>
                <p className='text-4xl'>STEM & Tinkering</p>
                <p className='text-2xl font-semibold'>Building Tomorrow's Innovators!</p>
                <p>
                  Be prepared to unravel the secrets of tomorrow! Our STEM & Tinkering workshop is your opportunity to explore, create, and innovate. Solve problems, engineer solutions, and craft new technologies for tomorrow. like Design and build a prosthetic limb using sensors and 3D printing to make a difference in people's lives today.
                </p>
              </FadeInSectionDownToUp>
            </div>
            <div className='w-1/2 xl:w-1/2 sm:w-full max-sm:w-full'>
              <img className='rounded-3xl' src='https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/STEM%20&%20TINKERING.jpg?updatedAt=1731128428430' alt='Winter Workshop' />
            </div>
          </div>
          <div className='flex max-sm:block justify-center items-center px-36 xl:px-36 sm:px-10 max-sm:px-10 py-10'>
            <div className='w-1/2 xl:w-1/2 sm:w-full max-sm:w-full'>
              <img className='rounded-3xl' src='https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Cover%20Img.jpg?updatedAt=1731129899730' alt='Winter Workshop' />
            </div>
            <div className='px-20 xl:px-20 sm:px-10 max-sm:px-0 max-sm:pt-5 w-full text-[#102e83]'>
              <FadeInSectionDownToUp>
                <p className='text-4xl'>Drones & Flying Simulations</p>
                <p className='text-2xl font-semibold'>The Future of Flight!</p>
                <p>
                  Take to the Skies and Experience the Future of Aviation! In our Drones & Flying Simulations workshop, you learn what it will be like when drones start helping us in extraordinary ways. Consider this: drones delivering medical supplies to remote areas, counting animal populations, and assisting farmers in optimizing crop health. You'll even discover how drones can save lives by acting as a flying ambulance in emergency situations.
                </p>
              </FadeInSectionDownToUp>
            </div>
          </div>
          <div className='flex max-sm:flex-col-reverse justify-center items-center px-36 xl:px-36 sm:px-10 max-sm:px-10 py-10'>
            <div className='px-20 xl:px-20 sm:px-10 max-sm:px-0 max-sm:pt-5 w-full text-end text-[#102e83]'>
              <FadeInSectionDownToUp>
                <p className='text-4xl'>3D Design & Printing</p>
                <p className='text-2xl font-semibold'>From Vision to Reality!</p>
                <p>
                  Let your imagination come to life in 3D! The workshop will teach you to design and print new innovative solutions for changing manufacturing, healthcare, and even outer space. Imagine: a future where the 3D printer controls the self-generating stack of manufacturing, healthcare, or outer space.
                </p>
              </FadeInSectionDownToUp>
            </div>
            <div className='w-1/2 xl:w-1/2 sm:w-full max-sm:w-full'>
              <img className='rounded-3xl' src='https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/3D%20designing%20and%20printing.jpg?updatedAt=1731128429577' alt='Winter Workshop' />
            </div>
          </div>
          <div className='flex max-sm:block justify-center items-center px-36 xl:px-36 sm:px-10 max-sm:px-10 py-10'>
            <div className='w-1/2 xl:w-1/2 sm:w-full max-sm:w-full'>
              <img className='rounded-3xl' src='https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Robotics%20with%20AI.jpg?updatedAt=1731128428443' alt='Winter Workshop' />
            </div>
            <div className='px-20 xl:px-20 sm:px-10 max-sm:px-0 max-sm:pt-5 w-full text-[#102e83]'>
              <FadeInSectionDownToUp>
                <p className='text-4xl'>Future Farming</p>
                <p className='text-2xl font-semibold'>Sustainable Food for a Growing World!</p>
                <p>
                  Sign up for the agricultural revolution! Learn how to produce food sustainably in modern ways in our Future Farming workshop. How will hydroponics, vertical gardens, and precision farming feed the world of tomorrow? Discover how to grow your own food in a teeny-tiny space and contribute to the environment.
                </p>
              </FadeInSectionDownToUp>
            </div>
          </div>
          <div className='flex max-sm:flex-col-reverse justify-center items-center px-36 xl:px-36 sm:px-10 max-sm:px-10 py-10'>
            <div className='px-20 xl:px-20 sm:px-10 max-sm:px-0 max-sm:pt-5 w-full text-end text-[#102e83]'>
              <FadeInSectionDownToUp>
                <p className='text-4xl'>Coding, Machine Learning, AR & VR</p>
                <p className='text-2xl font-semibold'>Shaping the Digital Frontier!</p>
                <p>
                  Welcome to the infinite possibility world! It gives you all the tools to create immersive experiences, intelligent apps, and innovative solutions. Shape the digital landscape of the future.
                </p>
              </FadeInSectionDownToUp>
            </div>
            <div className='w-1/2 xl:w-1/2 sm:w-full max-sm:w-full'>
              <img className='rounded-3xl' src='https://ik.imagekit.io/mhcockpit1/MHB/Landing%20Page/Coding%20ML,%20AR%20&%20VR.jpg?updatedAt=1731128428380' alt='Winter Workshop' />
            </div>
          </div>
        </div>
        <div className='flex justify-center items-center pt-10'>
          <button onClick={() => navigate('/winter-workshop/enrollment')} className='bg-[#0a195c] text-white text-2xl py-2 rounded-xl max-sm:mx-0 w-40'>Join Us</button>
        </div>
        <div className='py-5'>
          <FadeInSectionDownToUp>
            <p className='text-center font-bold text-4xl'>Pathways to Success</p>
            <div className='flex justify-center items-center'>
              <GoDotFill
                size={20}
                color='#3854cf'
              />
              <GoDotFill
                size={30}
                color='#0b1a5b'
              />
              <GoDotFill
                size={30}
                color='#0b1a5b'
              />
              <GoDotFill
                size={30}
                color='#0b1a5b'
              />
              <GoDotFill
                size={20}
                color='#3854cf'
              />
            </div>
          </FadeInSectionDownToUp>
        </div>
      </div>
      <div className='py-20 bg-[#3854cf]'>
        <div className='flex justify-center items-center py-10'>
          <div className='grid grid-cols-3 max-sm:grid-cols-2 gap-x-40 max-sm:gap-x-10 gap-y-10'>
            {pathway.map((icon, index) => (
              <div key={index}>
                <FadeInSectionDownToUp>
                  <div className='flex justify-center items-center'>
                    <img className='w-40' src={icon.src} alt='Winter Workshop' />
                  </div>
                  <div className='flex justify-center items-center'>
                    <div>
                      <p className='text-center text-2xl max-sm:text-xl text-white'>{icon.titleLine1}</p>
                      <p className='text-center text-2xl max-sm:text-xl text-white'>{icon.titleLine2}</p>
                    </div>
                  </div>
                </FadeInSectionDownToUp>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className='py-40'>
        <div className='pl-40 xl:pl-40 sm:pl-10 max-sm:pl-10'>
          <FadeInSectionDownToUp>
            <p className='text-7xl'>
              Why<br />
              Choose <span className='font-semibold text-[#0a195c]'>us</span>
            </p>
          </FadeInSectionDownToUp>
        </div>
        <div className='flex justify-center items-center py-10 max-sm:px-5'>
          <div className='grid grid-cols-3 max-sm:grid-cols-2 grid- gap-x-40 max-sm:gap-x-10 gap-y-10'>
            {whychoose1.map((icon, index) => (
              <div key={index}>
                <FadeInSectionDownToUp>
                  <div className='flex justify-center items-center'>
                    <img className='w-44' src={icon.src} alt='Winter Workshop' />
                  </div>
                  <div className='flex justify-center items-center'>
                    <div>
                      <p className='text-center text-3xl max-sm:text-xl font-semibold'>{icon.title}</p>
                      <p className='text-center text-2xl max-sm:text-xl'>{icon.titleLine1}</p>
                      <p className='text-center text-2xl max-sm:text-xl'>{icon.titleLine2}</p>
                    </div>
                  </div>
                </FadeInSectionDownToUp>
              </div>
            ))}
          </div>
        </div>
        {/* <div className='flex justify-center items-center py-10'>
          <div className='grid grid-cols-2 grid- gap-x-40 gap-y-10'>
            {whychoose2.map((icon, index) => (
              <div key={index}>
                <FadeInSectionDownToUp>
                  <div className='flex justify-center items-center'>
                    <img className='w-44' src={icon.src} alt='Winter Workshop' />
                  </div>
                  <div className='flex justify-center items-center'>
                    <div>
                      <p className='text-center text-3xl font-semibold'>{icon.title}</p>
                      <p className='text-center text-2xl'>{icon.titleLine1}</p>
                      <p className='text-center text-2xl'>{icon.titleLine2}</p>
                    </div>
                  </div>
                </FadeInSectionDownToUp>
              </div>
            ))}
          </div>
        </div> */}
        <div className='px-56 xl:px-56 sm:px-36 max-sm:px-5 py-20'>
          <FadeInSectionDownToUp>
            <p className='text-7xl max-sm:text-5xl'>
              About<br />
              <span className='font-semibold text-[#0a195c]'>MH Intellect.</span>
            </p>
          </FadeInSectionDownToUp>
          <div className='text-justify'>
            <FadeInSectionDownToUp>
              <p className='py-5 text-xl text-[#969696]'>
                Inspiring Young Minds, Empowering Future Innovators MH Intellect is dedicated to unlocking the potential in every young learner. Through hands-on workshops, interactive training, and innovative programs, we help students explore the world of science, technology, engineering, and aviation. Our mission is to make complex concepts fun and accessible, inspiring kids to dream big and think creatively.
              </p>
            </FadeInSectionDownToUp>
            <FadeInSectionDownToUp>
              <p className='text-xl text-[#969696]'>
                We bring together top experts, exciting tools, and real-world learning experiences in fields like robotics, AI, 3D printing, coding, and more. At MH Intellect, we believe that learning should be an adventure—one that prepares students not just for school, but for life. Join us to discover, create, and shape the future!
              </p>
            </FadeInSectionDownToUp>
          </div>
        </div>
      </div>
      {/* <ApplyNowModal isOpen={isModalOpen} onClose={closeModal} /> */}
      <div className="fixed top-1/2 left-0 z-50 -rotate-90">
        <button onClick={() => navigate('/winter-workshop/enrollment')} className='bg-[#fa00ff] text-white text-2xl py-2 w-40 rounded-b-xl absolute animate-bounce transition-transform duration-1000 ease-in-out'>Join Us</button>
      </div>
    </div>
  )
}
