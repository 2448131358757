import React, { useState } from 'react'
import axios from 'axios'
import { CountryCode } from '../../Components/Contact Us/CountryCode'

export default function Application() {

    const [formData, setFormData] = useState({
        name: '',
        dob: '',
        grade: '',
        school: '',
        countryCode: '',
        contact: '',
        email: '',
        parentGuardian: '',
        parentGuardianContact: '',
        city: '',
        fieldOfInterest: '',
        attendedAnyWorkshop: '',
        ifYesDescribe: '',
        specificTopicsOrSkills: '',
        futureCareer: '',
        careerPath: '',
        roleModel: '',
        careerGoal: '',
        activities: '',
        describeYourself: '',
        approachNewChallenge: '',
        motivatesYouToLearn: '',
        yourInvention: '',
        aboutTeamWork: '',
        responseToFeedback: '',
        processOrResult: '',
        challengingProblem: '',
        selfMotive: '',
        howDidYouKnow: '',
        gainSkillsAndKnowledge: '',
        yourSpecificTopics: '',
        futureUpdates: '',
        acknowledge: false,
        parentGuardianConsent: false,
    })

    const [errors, setErrors] = useState({})

    const validate = () => {
        const newErrors = {}
        if (!formData.name) newErrors.name = 'Name is required'
        if (!formData.dob) newErrors.dob = 'Date of birth is required'
        if (!formData.grade) newErrors.grade = 'Grade/Year is required'
        if (!formData.school) newErrors.school = 'School Name is required'
        if (!formData.contact) newErrors.contact = 'Contact number is required'
        if (!formData.email) newErrors.email = 'Email is required'
        if (!formData.parentGuardian) newErrors.parentGuardian = 'Parent/Guardian name is required'
        if (!formData.parentGuardianContact) newErrors.parentGuardianContact = 'Parent/Guardian contact number is required'
        if (!formData.city) newErrors.city = 'City/Town is required'
        if (!formData.fieldOfInterest) newErrors.fieldOfInterest = 'Field of interest is required'
        if (!formData.attendedAnyWorkshop) newErrors.attendedAnyWorkshop = 'Please indicate if you have attended any workshops'
        if (!formData.ifYesDescribe) newErrors.ifYesDescribe = 'Please describe your answer'
        if (!formData.specificTopicsOrSkills) newErrors.specificTopicsOrSkills = 'Please describe your answer'
        if (!formData.futureCareer) newErrors.futureCareer = 'Please select any one of these'
        if (!formData.careerPath) newErrors.careerPath = 'Describe why you are interested'
        if (!formData.roleModel) newErrors.roleModel = 'Describe your role model'
        if (!formData.careerGoal) newErrors.careerGoal = 'Describe your career goal'
        if (!formData.activities) newErrors.activities = 'Describe your activities'
        if (!formData.describeYourself) newErrors.describeYourself = 'Describe yourself'
        if (!formData.approachNewChallenge) newErrors.approachNewChallenge = 'Please select any one of these'
        if (!formData.motivatesYouToLearn) newErrors.motivatesYouToLearn = 'Please select any one of these'
        if (!formData.yourInvention) newErrors.yourInvention = 'Please describe your answer'
        if (!formData.aboutTeamWork) newErrors.aboutTeamWork = 'Please select any one of these'
        if (!formData.responseToFeedback) newErrors.responseToFeedback = 'Please select any one of these'
        if (!formData.processOrResult) newErrors.processOrResult = 'Please describe your answer'
        if (!formData.challengingProblem) newErrors.challengingProblem = 'Please describe your answer'
        if (!formData.selfMotive) newErrors.selfMotive = 'Please describe your answer'
        if (!formData.howDidYouKnow) newErrors.howDidYouKnow = 'Please select any one of these'
        if (!formData.gainSkillsAndKnowledge) newErrors.gainSkillsAndKnowledge = 'Please describe your answer'
        if (!formData.yourSpecificTopics) newErrors.yourSpecificTopics = 'Please describe your answer'
        if (!formData.futureUpdates) newErrors.futureUpdates = 'Please select any one of these'
        if (!formData.acknowledge) newErrors.acknowledge = 'You must acknowledge the information'
        if (!formData.parentGuardianConsent) newErrors.parentGuardianConsent = 'Consent is required'

        if (Object.keys(newErrors).length > 0) {
            alert('Please fill in all required fields.')
        }

        return newErrors
    }

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const validationErrors = validate()
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors)
            return
        }

        try {
            const postData = {
                Name: formData.name,
                DOB: formData.dob,
                Grade: formData.grade,
                School: formData.school,
                Country_Code: formData.countryCode,
                Contact: formData.contact,
                Email: formData.email,
                Parent_or_Guardian: formData.parentGuardian,
                Parent_or_Guardian_Contact: formData.parentGuardianContact,
                City: formData.city,
                Field_of_Interest: formData.fieldOfInterest,
                Attended_any_Workshop: formData.attendedAnyWorkshop,
                If_Yes_Describe: formData.ifYesDescribe,
                Topics_or_Skills: formData.specificTopicsOrSkills,
                Future_Career: formData.futureCareer,
                Career_Path: formData.careerPath,
                Role_Model: formData.roleModel,
                Career_Goal: formData.careerGoal,
                Activities: formData.activities,
                Describe_Yourself: formData.describeYourself,
                New_Challenge: formData.approachNewChallenge,
                What_Motivates_You_to_Learn: formData.motivatesYouToLearn,
                Your_Invention: formData.yourInvention,
                About_Team_Work: formData.aboutTeamWork,
                Response_to_Feedeback: formData.responseToFeedback,
                Process_or_Result: formData.processOrResult,
                Challenging_Problem: formData.challengingProblem,
                Self_Motive: formData.selfMotive,
                How_did_you_know: formData.howDidYouKnow,
                Gain_Skills_and_Knowledge: formData.gainSkillsAndKnowledge,
                Your_Specific_Topics: formData.yourSpecificTopics,
                Did_you_want_Future_Updates: formData.futureUpdates,
                Acknowledge: formData.acknowledge,
                Parent_Guardian_Consent: formData.parentGuardianConsent
            }

            const response = await axios.post('https://sheetdb.io/api/v1/ui1fxwi66gmow', postData)

            console.log('Form submitted successfully', response)
            alert('Your application has been submitted successfully!')
            setFormData({
                name: '',
                dob: '',
                grade: '',
                school: '',
                countryCode: '',
                contact: '',
                email: '',
                parentGuardian: '',
                parentGuardianContact: '',
                city: '',
                fieldOfInterest: '',
                attendedAnyWorkshop: '',
                ifYesDescribe: '',
                specificTopicsOrSkills: '',
                futureCareer: '',
                careerPath: '',
                roleModel: '',
                careerGoal: '',
                activities: '',
                describeYourself: '',
                approachNewChallenge: '',
                motivatesYouToLearn: '',
                yourInvention: '',
                aboutTeamWork: '',
                responseToFeedback: '',
                processOrResult: '',
                challengingProblem: '',
                selfMotive: '',
                howDidYouKnow: '',
                gainSkillsAndKnowledge: '',
                yourSpecificTopics: '',
                futureUpdates: '',
                acknowledge: false,
                parentGuardianConsent: false,
            })
            window.location.href = '/winter-workshop'
        } catch (error) {
            console.error('Error submitting form:', error)
            alert('There was an error submitting your application. Please try again later.')
        }
    }

    return (
        <div className='bg-gradient-to-b from-[#b876ff] via-[#01c8ff99] to-[#3401ff99] py-10 max-sm:py-5'>
            <div className='mx-40 xl:mx-40 sm:mx-20 max-sm:mx-5 px-10 max-sm:px-5 py-10 bg-white bg-opacity-50 rounded-3xl max-sm:rounded-2xl'>
                <div>
                    <p className='text-center text-4xl font-semibold pb-2 border-b border-black'>Enroll Now</p>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className='flex justify-center items-center py-5'>
                        <div className='grid grid-cols-3 max-sm:grid-cols-1 gap-10 max-sm:gap-5 w-full'>
                            <div>
                                <label className='block'>Name:</label>
                                <input
                                    className={`bg-transparent border ${errors.name ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    placeholder='Your Full Name'
                                />
                                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                            </div>
                            <div>
                                <label className='block'>Date of Birth:</label>
                                <input
                                    className={`bg-transparent border ${errors.dob ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='date'
                                    name='dob'
                                    value={formData.dob}
                                    onChange={handleChange}
                                />
                                {errors.dob && <p className="text-red-500 text-sm">{errors.dob}</p>}
                            </div>
                            <div>
                                <label className='block'>Grade/Year:</label>
                                {/* <input className='bg-transparent border border-black rounded w-full py-2 px-3' type='text' placeholder='Grade/Year' /> */}
                                <select
                                    className={`bg-transparent border ${errors.grade ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='grade'
                                    value={formData.grade}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Yours</option>
                                    <option>1st</option>
                                    <option>2nd</option>
                                    <option>3rd</option>
                                    <option>4th</option>
                                    <option>5th</option>
                                    <option>6th</option>
                                    <option>7th</option>
                                    <option>8th</option>
                                    <option>9th</option>
                                    <option>10th</option>
                                    <option>11th</option>
                                    <option>12th</option>
                                </select>
                                {errors.grade && <p className="text-red-500 text-sm">{errors.grade}</p>}
                            </div>
                            <div>
                                <label className='block'>School Name:</label>
                                <input
                                    className={`bg-transparent border ${errors.school ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='School Name'
                                    name='school'
                                    value={formData.school}
                                    onChange={handleChange}
                                />
                                {errors.school && <p className='text-red-500 text-sm'>{errors.school}</p>}
                            </div>
                            <div>
                                <label className='block'>Contact Number:</label>
                                <div className='flex justify-center items-center gap-2'>
                                    <select
                                        className={`bg-transparent border text-center ${errors.school ? 'border-red-500' : 'border-black'} rounded w-1/4 py-2`}
                                        name='countryCode'
                                        value={formData.countryCode}
                                        onChange={handleChange}
                                    >
                                        {CountryCode.map(code => (
                                            <option
                                                key={code.value}
                                                value={code.value}
                                                disabled={code.disabled}
                                                selected={code.selected}
                                                hidden={code.hidden}
                                                className='text-start'
                                            >
                                                {code.value || code.text}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        className={`bg-transparent border ${errors.contact ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                        type='number'
                                        placeholder='Contact Number'
                                        name='contact'
                                        value={formData.contact}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.contact && <p className='text-red-500 text-sm'>{errors.contact}</p>}
                            </div>
                            <div>
                                <label className='block'>Email:</label>
                                <input
                                    className={`bg-transparent border ${errors.email ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='email'
                                    placeholder='Email'
                                    name='email'
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <p className='text-red-500 text-sm'>{errors.email}</p>}
                            </div>
                            <div>
                                <label className='block'>Parent/Guardian Name:</label>
                                <input
                                    className={`bg-transparent border ${errors.parentGuardian ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Parent/Guardian Name'
                                    name='parentGuardian'
                                    value={formData.parentGuardian}
                                    onChange={handleChange}
                                />
                                {errors.parentGuardian && <p className='text-red-500 text-sm'>{errors.parentGuardian}</p>}
                            </div>
                            <div>
                                <label className='block'>Parent/Guardian Contact Number:</label>
                                <div className='flex justify-center items-center gap-2'>
                                    <select
                                        className={`bg-transparent border text-center ${errors.school ? 'border-red-500' : 'border-black'} rounded w-1/4 py-2`}
                                        name='countryCode'
                                        value={formData.countryCode}
                                        onChange={handleChange}
                                    >
                                        {CountryCode.map(code => (
                                            <option
                                                key={code.value}
                                                value={code.value}
                                                disabled={code.disabled}
                                                selected={code.selected}
                                                hidden={code.hidden}
                                                className='text-start'
                                            >
                                                {code.value || code.text}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        className={`bg-transparent border ${errors.parentGuardianContact ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                        type='number'
                                        placeholder='Parent/Guardian Contact Number'
                                        name='parentGuardianContact'
                                        value={formData.parentGuardianContact}
                                        onChange={handleChange}
                                    />
                                </div>
                                {errors.parentGuardianContact && <p className='text-red-500 text-sm'>{errors.parentGuardianContact}</p>}
                            </div>
                            <div>
                                <label className='block'>City/Town:</label>
                                <input
                                    className={`bg-transparent border ${errors.city ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='City/Town'
                                    name='city'
                                    value={formData.city}
                                    onChange={handleChange}
                                />
                                {errors.city && <p className='text-red-500 text-sm'>{errors.city}</p>}
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-center items-center py-5'>
                        <div className='grid grid-cols-2 max-sm:grid-cols-1 gap-10 max-sm: w-full'>
                            <div>
                                <label className='block'>Which of the following fields are you most interested in?</label>
                                <select
                                    className={`bg-transparent border ${errors.fieldOfInterest ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='fieldOfInterest'
                                    value={formData.fieldOfInterest}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Your Interest</option>
                                    <option>3D Printing</option>
                                    <option>Artificial Intelligence</option>
                                    <option>Robotics</option>
                                    <option>Aviation</option>
                                    <option>Aerospace</option>
                                </select>
                                {errors.fieldOfInterest && <p className='text-red-500 text-sm'>{errors.fieldOfInterest}</p>}
                            </div>
                            <div>
                                <label className='block'>Have you attended any STEM workshops or programs before?</label>
                                <select
                                    className={`bg-transparent border ${errors.attendedAnyWorkshop ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='attendedAnyWorkshop'
                                    value={formData.attendedAnyWorkshop}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden></option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                                {errors.attendedAnyWorkshop && <p className='text-red-500 text-sm'>{errors.attendedAnyWorkshop}</p>}
                            </div>
                            <div>
                                <label className='block'>If yes, please briefly describe your experience:</label>
                                <input
                                    className={`bg-transparent border ${errors.ifYesDescribe ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Describe your experience'
                                    name='ifYesDescribe'
                                    value={formData.ifYesDescribe}
                                    onChange={handleChange}
                                />
                                {errors.ifYesDescribe && <p className='text-red-500 text-sm'>{errors.ifYesDescribe}</p>}
                            </div>
                            <div>
                                <label className='block'>What specific topics or skills do you hope to learn from this workshop?</label>
                                <input
                                    className={`bg-transparent border ${errors.specificTopicsOrSkills ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Your Answer'
                                    name='specificTopicsOrSkills'
                                    value={formData.specificTopicsOrSkills}
                                    onChange={handleChange}
                                />
                                {errors.specificTopicsOrSkills && <p className='text-red-500 text-sm'>{errors.specificTopicsOrSkills}</p>}
                            </div>
                            <div>
                                <label className='block'>What careers are you interested in pursuing in the future?</label>
                                <select
                                    className={`bg-transparent border ${errors.futureCareer ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='futureCareer'
                                    value={formData.futureCareer}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Your Future</option>
                                    <option>Medicine</option>
                                    <option>Engineering</option>
                                    <option>Science/Research</option>
                                    <option>AI & ML</option>
                                    <option>Technology</option>
                                    <option>Aviation/Aerospace</option>
                                    <option>Arts</option>
                                    <option>Marine</option>
                                </select>
                                {errors.futureCareer && <p className='text-red-500 text-sm'>{errors.futureCareer}</p>}
                            </div>
                            <div>
                                <label className='block'>Why does this career path interest you?</label>
                                <input
                                    className={`bg-transparent border ${errors.careerPath ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Describe Your Answer'
                                    name='careerPath'
                                    value={formData.careerPath}
                                    onChange={handleChange}
                                />
                                {errors.careerPath && <p className='text-red-500 text-sm'>{errors.careerPath}</p>}
                            </div>
                            <div>
                                <label className='block'>Do you have any role models in these fields?</label>
                                <input
                                    className={`bg-transparent border ${errors.roleModel ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Your Answer'
                                    name='roleModel'
                                    value={formData.roleModel}
                                    onChange={handleChange}
                                />
                                {errors.roleModel && <p className='text-red-500 text-sm'>{errors.roleModel}</p>}
                            </div>
                            <div>
                                <label className='block'>What would you like to achieve in your career within the next 10 years?</label>
                                <input
                                    className={`bg-transparent border ${errors.careerGoal ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Your Answer'
                                    name='careerGoal'
                                    value={formData.careerGoal}
                                    onChange={handleChange}
                                />
                                {errors.careerGoal && <p className='text-red-500 text-sm'>{errors.careerGoal}</p>}
                            </div>
                            <div>
                                <label className='block'>Do you have any projects, activities, or hobbies related to STEM?</label>
                                <input
                                    className={`bg-transparent border ${errors.activities ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Describe Briefly'
                                    name='activities'
                                    value={formData.activities}
                                    onChange={handleChange}
                                />
                                {errors.activities && <p className='text-red-500 text-sm'>{errors.activities}</p>}
                            </div>
                            <div>
                                <label className='block'>How would you describe yourself?</label>
                                <select
                                    className={`bg-transparent border ${errors.describeYourself ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='describeYourself'
                                    value={formData.describeYourself}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>How would you describe yourself?</option>
                                    <option>Curious</option>
                                    <option>Creative</option>
                                    <option>Analytical</option>
                                    <option>Detail-oriented</option>
                                    <option>Collaborative</option>
                                    <option>Independent thinker</option>
                                </select>
                                {errors.describeYourself && <p className='text-red-500 text-sm'>{errors.describeYourself}</p>}
                            </div>
                            <div>
                                <label className='block'>How do you usually approach a new challenge?</label>
                                <select
                                    className={`bg-transparent border ${errors.approachNewChallenge ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='approachNewChallenge'
                                    value={formData.approachNewChallenge}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>How do you usually approach a new challenge?</option>
                                    <option>Dive right in</option>
                                    <option>Analyze first, then act</option>
                                    <option>Seek guidance or collaborate with others</option>
                                    <option>Take time to plan carefully</option>
                                </select>
                                {errors.approachNewChallenge && <p className='text-red-500 text-sm'>{errors.approachNewChallenge}</p>}
                            </div>
                            <div>
                                <label className='block'>What motivates you to learn something new?</label>
                                <select
                                    className={`bg-transparent border ${errors.motivatesYouToLearn ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='motivatesYouToLearn'
                                    value={formData.motivatesYouToLearn}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>What motivates you to learn something new?</option>
                                    <option>Personal interest</option>
                                    <option>School requirements</option>
                                    <option>Career ambitions</option>
                                    <option>Desire to solve real-world problems</option>
                                </select>
                                {errors.motivatesYouToLearn && <p className='text-red-500 text-sm'>{errors.motivatesYouToLearn}</p>}
                            </div>
                            <div>
                                <label className='block'>If you could invent anything to solve a problem, what would it be and why?</label>
                                <input
                                    className={`bg-transparent border ${errors.yourInvention ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Your Answer'
                                    name='yourInvention'
                                    value={formData.yourInvention}
                                    onChange={handleChange}
                                />
                                {errors.yourInvention && <p className='text-red-500 text-sm'>{errors.yourInvention}</p>}
                            </div>
                            <div>
                                <label className='block'>How do you feel about working in a team?</label>
                                <select
                                    className={`bg-transparent border ${errors.aboutTeamWork ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='aboutTeamWork'
                                    value={formData.aboutTeamWork}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Your Answer</option>
                                    <option>I enjoy it and actively contribute.</option>
                                    <option>I prefer working alone but can collaborate if needed.</option>
                                    <option>I’m neutral, open to both teamwork and solo work.</option>
                                </select>
                                {errors.aboutTeamWork && <p className='text-red-500 text-sm'>{errors.aboutTeamWork}</p>}
                            </div>
                            <div>
                                <label className='block'>How do you respond to feedback on your work?</label>
                                <select
                                    className={`bg-transparent border ${errors.responseToFeedback ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='responseToFeedback'
                                    value={formData.responseToFeedback}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Your Answer</option>
                                    <option>I appreciate it and use it to improve.</option>
                                    <option>I consider it, but rely on my own judgment.</option>
                                    <option>I prefer to work independently without feedback.</option>
                                </select>
                                {errors.responseToFeedback && <p className='text-red-500 text-sm'>{errors.responseToFeedback}</p>}
                            </div>
                            <div>
                                <label className='block'>What do you think is more important in learning: the process or the result?</label>
                                <input
                                    className={`bg-transparent border ${errors.processOrResult ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Explain why'
                                    name='processOrResult'
                                    value={formData.processOrResult}
                                    onChange={handleChange}
                                />
                                {errors.processOrResult && <p className='text-red-500 text-sm'>{errors.processOrResult}</p>}
                            </div>
                            <div>
                                <label className='block'>Describe a situation when you faced a challenging problem. How did you approach it, and what was the outcome?</label>
                                <input
                                    className={`bg-transparent border ${errors.challengingProblem ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Your Answer'
                                    name='challengingProblem'
                                    value={formData.challengingProblem}
                                    onChange={handleChange}
                                />
                                {errors.challengingProblem && <p className='text-red-500 text-sm'>{errors.challengingProblem}</p>}
                            </div>
                            <div>
                                <label className='block'>How do you keep yourself motivated during long-term projects or learning experiences?</label>
                                <input
                                    className={`bg-transparent border ${errors.selfMotive ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Your Answer'
                                    name='selfMotive'
                                    value={formData.selfMotive}
                                    onChange={handleChange}
                                />
                                {errors.selfMotive && <p className='text-red-500 text-sm'>{errors.selfMotive}</p>}
                            </div>
                            <div>
                                <label className='block'>How did you hear about this workshop?</label>
                                <select
                                    className={`bg-transparent border ${errors.howDidYouKnow ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='howDidYouKnow'
                                    value={formData.howDidYouKnow}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Your Answer</option>
                                    <option>Social Media</option>
                                    <option>School</option>
                                    <option>Friends/Family</option>
                                    <option>Online Search</option>
                                </select>
                                {errors.howDidYouKnow && <p className='text-red-500 text-sm'>{errors.howDidYouKnow}</p>}
                            </div>
                            <div>
                                <label className='block'>Are there any other skills or knowledge you would like to gain during this time?</label>
                                <input
                                    className={`bg-transparent border ${errors.gainSkillsAndKnowledge ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Answer Shortly'
                                    name='gainSkillsAndKnowledge'
                                    value={formData.gainSkillsAndKnowledge}
                                    onChange={handleChange}
                                />
                                {errors.gainSkillsAndKnowledge && <p className='text-red-500 text-sm'>{errors.gainSkillsAndKnowledge}</p>}
                            </div>
                            <div>
                                <label className='block'>Do you have any specific topics you would like the instructors to cover?</label>
                                <input
                                    className={`bg-transparent border ${errors.yourSpecificTopics ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    type='text'
                                    placeholder='Answer Shortly'
                                    name='yourSpecificTopics'
                                    value={formData.yourSpecificTopics}
                                    onChange={handleChange}
                                />
                                {errors.yourSpecificTopics && <p className='text-red-500 text-sm'>{errors.yourSpecificTopics}</p>}
                            </div>
                            <div>
                                <label className='block'>Would you like to receive updates on future workshops or programs?</label>
                                <select
                                    className={`bg-transparent border ${errors.futureUpdates ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                    name='futureUpdates'
                                    value={formData.futureUpdates}
                                    onChange={handleChange}
                                >
                                    <option value="" disabled selected hidden>Select Your Answer</option>
                                    <option>Yes</option>
                                    <option>No</option>
                                </select>
                                {errors.futureUpdates && <p className='text-red-500 text-sm'>{errors.futureUpdates}</p>}
                            </div>
                            <div className='flex'>
                                <div>
                                    <input
                                        className={`bg-transparent border ${errors.acknowledge ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                        type='checkbox'
                                        placeholder='Answer Shortly'
                                        name='acknowledge'
                                        value={formData.acknowledge}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    <label className='block'>I hereby acknowledge that all information provided is accurate to the best of my knowledge.</label>
                                    {errors.acknowledge && <p className='text-red-500 text-sm'>{errors.acknowledge}</p>}
                                </div>
                            </div>
                            <div className='flex'>
                                <div>
                                    <input
                                        className={`bg-transparent border ${errors.parentGuardianConsent ? 'border-red-500' : 'border-black'} rounded w-full py-2 px-3`}
                                        type='checkbox'
                                        placeholder='Answer Shortly'
                                        name='parentGuardianConsent'
                                        value={formData.parentGuardianConsent}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div>
                                    <label className='block'>Parent/Guardian Consent: give permission for my child to attend this workshop and participate in related activities.</label>
                                    {errors.parentGuardianConsent && <p className='text-red-500 text-sm'>{errors.parentGuardianConsent}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' flex justify-center items-center py-10'>
                        <button
                            type="submit"
                            className='bg-white hover:bg-slate-300 hover:scale-105 transition-all ease-linear duration-300 text-black text-xl py-2 px-5 rounded'
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}