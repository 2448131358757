// import React, { useState } from 'react';
// import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
// import Video1 from '../../Components/Video/Video1';

// const images = Array.from({ length: 68 }, (_, i) => ({
//     src: `/Gallery/${i + 1}.jpg`,
//     alt: 'Gallery',
// }));

// const MOUimages = Array.from({ length: 3 }, (_, i) => ({
//     src: `/Gallery/MOU/MOU ${i + 1}.jpg`,
//     alt: 'MOU',
// }));

// const ImageColumn = ({ src, alt }) => (
//     <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/3 p-4 max-sm:p-0">
//         <center>
//             <div className="p-3">
//                 <FadeInSectionDownToUp>
//                     <img src={src} alt={alt} className="w-full h-auto rounded-3xl xl:rounded-3xl max-sm:rounded-xl" />
//                 </FadeInSectionDownToUp>
//             </div>
//         </center>
//     </div>
// );

// export default function Gallery() {

//     const TestimonialVideos = [

//         {
//             videoSrc: '/Experiments/Other Videos/MH Intellect KRM 2 New.mp4',
//             thumbnailSrc: '/Experiments/Thumbnail/MH Intellect KRM 2 New.png',
//         },
//         {
//             videoSrc: '/Experiments/Other Videos/MH Intellect_Testimonial KRM New.mp4',
//             thumbnailSrc: '/Experiments/Thumbnail/MH Intellect_Testimonial KRM New.png',
//         },

//     ]

//     const [playingVideo, setPlayingVideo] = useState(null);

//     const handlePlay = (videoSrc) => {
//       setPlayingVideo(videoSrc);
//     };

//     return (
//         <div className='bg-white dark:bg-black'>
//             <div className="text-9xl xl:text-6xl max-sm:text-6xl py-20 from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent text-center">
//                 <h1>Our Clients.</h1>
//             </div>
//             <div className="px-10 xl:px-10 max-sm:px-0">
//                 <div className="flex flex-wrap">
//                     {MOUimages.map((image, index) => (
//                         <ImageColumn key={index} src={image.src} alt={image.alt} />
//                     ))}
//                 </div>
//             </div>
//             <div className="text-9xl xl:text-6xl max-sm:text-6xl py-20 from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent text-center">
//                 <h1>Our Program.</h1>
//             </div>
//             <div>
//                 <div className="px-10 xl:px-10 max-sm:px-0">
//                     <div className="container mx-auto">
//                         <div className="flex flex-wrap">
//                             {images.map((image, index) => (
//                                 <ImageColumn key={index} src={image.src} alt={image.alt} />
//                             ))}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className="text-9xl xl:text-6xl max-sm:text-6xl py-20 from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent text-center">
//                 <h1>Testimonials.</h1>
//             </div>
//             <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-5 px-10 max-sm:px-5 pb-5">
//                 {TestimonialVideos.map((video, index) => (
//                     <div key={index}>
//                         <FadeInSectionDownToUp>
//                             <Video1
//                                 videoSrc={video.videoSrc}
//                                 thumbnailSrc={video.thumbnailSrc}
//                                 className="rounded-3xl"
//                                 isPlaying={playingVideo === video.videoSrc}
//                                 onPlay={handlePlay}
//                             />
//                         </FadeInSectionDownToUp>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// }



import React, { useState } from 'react';
import FadeInSectionDownToUp from '../../Components/FadeAnimations/FadeInSectionDownToUp';
import Video1 from '../../Components/Video/Video1';
import GalleryModal from '../../Components/Gallery/GalleryModal';
import Marquee from 'react-fast-marquee';

const images = Array.from({ length: 180 }, (_, i) => ({
    src: `https://ik.imagekit.io/mhcockpit1/MHB/Gallery/Programs/1/${i + 1}.jpg`,
    alt: 'Gallery',
}));

// const MOUimages = Array.from({ length: 3 }, (_, i) => ({
//     src: `/Gallery/MOU/MOU ${i + 1}.jpg`,
//     alt: 'MOU',
// }));

const ImageColumn = ({ src, alt, onClick }) => (
    <div className="w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/3 p-4 max-sm:p-0">
        <center>
            <div className="p-3">
                <FadeInSectionDownToUp>
                    <img src={src} alt={alt} onClick={() => onClick(src)} className="w-full h-auto rounded-3xl xl:rounded-3xl max-sm:rounded-xl cursor-pointer" />
                </FadeInSectionDownToUp>
            </div>
        </center>
    </div>
);


const Gallery = () => {
    const [playingVideo, setPlayingVideo] = useState(null);
    const [modalImageSrc, setModalImageSrc] = useState(null);

    const handlePlay = (videoSrc) => {
        setPlayingVideo(videoSrc);
    };

    const openModal = (src) => {
        setModalImageSrc(src);
    };

    const closeModal = () => {
        setModalImageSrc(null);
    };

    const TestimonialVideos = [

        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_6.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_3.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_5.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_4.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_1.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_2.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_7.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_8.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/Tes_9.mp4',
            // thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/MH%20Intellect%20KRM%202%20New.mp4',
            thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect%20KRM%202%20New.png',
        },
        {
            videoSrc: 'https://mh-intellect.blr1.cdn.digitaloceanspaces.com/MH%20Intellect_Testimonial%20KRM%20New.mp4',
            thumbnailSrc: 'https://ik.imagekit.io/mhcockpit1/MHB/Experiments/Thumbnail/MH%20Intellect_Testimonial%20KRM%20New.png',
        },

    ]

    return (
        <div className='bg-white dark:bg-black'>
            <div className="text-9xl xl:text-6xl sm:text-5xl max-sm:text-4xl py-20 from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent text-center">
                <h1>Our Clients.</h1>
            </div>
            <div className="px-10 xl:px-10 max-sm:px-0">
                {/* <div className="flex flex-wrap">
                    {MOUimages.map((image, index) => (
                        <ImageColumn key={index} src={image.src} alt={image.alt} onClick={openModal} />
                    ))}
                </div> */}
                <Marquee speed={200}>
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/ASPAM.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/good%20word%20public.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/krm%20matric.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/kaligi%20ranganathan%20montford.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/krm%20public.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/jamal.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/vels%20logo.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/malla%20reddy.jpg' alt='LOGO' />
                    <img className='w-72 mx-10 my-5 max-sm:w-36 max-sm:py-5 max-sm:px-5 rounded-xl' src='https://ik.imagekit.io/mhcockpit1/MHB/School%20&%20College%20LOGO/crescent.jpg' alt='LOGO' />
                </Marquee>
            </div>
            <div className="text-9xl xl:text-6xl sm:text-5xl max-sm:text-4xl py-20 from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent text-center">
                <h1>Our Program.</h1>
            </div>
            <div>
                <div className="px-10 xl:px-10 max-sm:px-0">
                    <div className="container mx-auto">
                        <div className="flex flex-wrap">
                            {images.map((image, index) => (
                                <ImageColumn key={index} src={image.src} alt={image.alt} onClick={openModal} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-9xl xl:text-6xl sm:text-5xl max-sm:text-4xl py-20 from-purple-600 via-pink-600 to-blue-600 bg-gradient-to-b bg-clip-text text-transparent text-center">
                <h1>Testimonials.</h1>
            </div>
            <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-5 px-10 max-sm:px-5 pb-5">
                {TestimonialVideos.map((video, index) => (
                    <div key={index}>
                        <FadeInSectionDownToUp>
                            <Video1
                                videoSrc={video.videoSrc}
                                thumbnailSrc={video.thumbnailSrc}
                                className="rounded-3xl"
                                isPlaying={playingVideo === video.videoSrc}
                                onPlay={handlePlay}
                            />
                        </FadeInSectionDownToUp>
                    </div>
                ))}
            </div>

            {/* Modal Component */}
            <GalleryModal
                isOpen={!!modalImageSrc}
                imageSrc={modalImageSrc}
                onClose={closeModal}
            />
        </div>
    );
};

export default Gallery;
